import { useRouter } from "next/router"
import { useEffect } from "react"
import { AuthStatus, useAuthStore } from "../../stores/useAuthStore"
import Info from "../../components/Info"
import Logo from "../../components/Logo"
import { JASMINE_ENDPOINT } from "../../util/consts"

const Login = () => {
    const router = useRouter()
    const authenticated = useAuthStore((state) => state.authStatus === AuthStatus.Authenticated)
    const authFailure = useAuthStore((state) => state.authStatus === AuthStatus.Failed)

    useEffect(() => {
        if (authenticated) {
            router.push("/search")
        }
    }, [authenticated, router])

    return (
        !authenticated && (
            <div id='rt'>
                <div className={`mx-4 mt-10 md:mt-20 place-self-center`}>
                    <div className={`w-full place-content-center place-items-center justify-center`}>
                        <Logo center={true} />
                        <p className='font-bold text-center text-jade-primary'>
                            You have been logged out. Please log into your JADE Professional account to use Jasmine Search.
                        </p>
                    </div>
                    <Info authenticationFailed={authFailure} endpoint={JASMINE_ENDPOINT} />
                    {/* <Stats /> */}
                </div>
            </div>
        )
    )
}

export default Login

