import Image from "next/image"
import Link from "next/link"

interface LogoProps {
    center: boolean;
}

const Logo = (props: LogoProps) => {
    const { center = true } = props

    const handleLogoJustification = (centre: boolean) => (centre ? "justify-center" : "")

    return (
        <div className={`flex-shrink-0 flex ${handleLogoJustification(center)}`}>
            {/* <Link href="/search" className={`inline-flex`}> */}
            <span className={`sr-only`}>JADE</span>
            <Image alt={"Jasmine Logo"} height={"126"} width={"126"} src='/logos/jasmine.svg' />
            {/* </Link> */}
        </div>
    )
}

export default Logo
