import { useEffect, useState } from "react"
import { JADEAuthURL } from "../util/consts"
import HTTPClient from "../util/HttpClient"
import ProfessionalModal from "./ProfessionalModal"

interface Props {
    endpoint: string;
    authenticationFailed: boolean;
}

type OAuthToken = {
    clientKey: string;
};

const LoginButton = ({ endpoint, authenticationFailed }: Props) => {
    const [OAuthToken, setOAuthToken] = useState<OAuthToken>()

    useEffect(() => {
        const getTempCreds = async () => {
            const Client: HTTPClient = new HTTPClient(endpoint)
            try {
                const resp = (await Client.get("/api/e2/auth/jasmine")).data as any
                setOAuthToken(resp)
            } catch (err) {
                // console.error(err)
            }
        }
        getTempCreds()
    }, [endpoint])

    return (
        <div className={`mb-12 mx-auto flex flex-row place-content-center space-x-5`}>
            <div>
                <div className={`flex flex-row place-content-center space-x-5 mx-auto`}>
                    <div>
                        <form action={`${JADEAuthURL}/oauth-authenticate`}>
                            <input type='hidden' name='oauth_token' value={`${OAuthToken?.clientKey}`} />
                            <button
                                className={`w-full inline-flex items-center px-4 py-2 border border-transparent text-base font-plexSans text-center font-bold rounded-md shadow-md text-white bg-jade-primary focus:outline-none pl-12 disabled:border-0 disabled:opacity-75 hover:ring-2 hover:ring-green-600 transition duration-500`}
                                type='submit'
                                disabled={!OAuthToken || !OAuthToken.clientKey}
                            >
                                {OAuthToken?.clientKey ? (
                                    <div className={`h-8 w-8 -ml-8 mr-3 bg-no-repeat bg-contain bg-jade`} />
                                ) : (
                                    <svg
                                        className={`animate-spin -ml-8 mr-3 h-8 w-8 text-white`}
                                        fill='none'
                                        viewBox='0 0 24 24'
                                    >
                                        <circle
                                            className={`opacity-25`}
                                            cx='12'
                                            cy='12'
                                            r='10'
                                            stroke='currentColor'
                                            strokeWidth='4'
                                        ></circle>
                                        <path
                                            className={`opacity-75`}
                                            fill='currentColor'
                                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                        ></path>
                                    </svg>
                                )}
                                CONTINUE WITH JADE PROFESSIONAL
                            </button>
                        </form>
                    </div>
                </div>
                <ProfessionalModal showModal={authenticationFailed} />
            </div>
        </div>
    )
}

export default LoginButton
