import { useEffect, useState } from "react"

interface Props {
    showModal?: boolean;
}

const ProfessionalModal = ({ showModal }: Props) => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (showModal) {
            setOpen(true)
        }
    }, [showModal])

    return open
        ? (
            <div
                tabIndex={-1}
                className={`z-50 ${
                    open
                        ? ""
                        : "hidden"
                } flex items-center justify-center h-screen overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 md:h-full`}
            >
                <div
                    className={`bg-unfocussed-gray overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex`}
                >
                    <div className={`relative max-w-2xl bg-white rounded-lg shadow`}>
                        <div className={`flex justify-between items-start p-4 rounded-t`}>
                            <h3 className={`m-1 ml-2 text-xl font-semibold text-gray-900`}>
                            Sorry, you do not have access to this feature
                            </h3>
                            <button
                                type='button'
                                onClick={() => setOpen(false)}
                                className={`text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center`}
                            >
                                <svg aria-hidden='true' className={`w-5 h-5`} fill='currentColor' viewBox='0 0 20 20'>
                                    <path
                                        fillRule='evenodd'
                                        d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                                        clipRule='evenodd'
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <div className={`p-6`}>
                            <p className={`text-base text-gray-500`}>
                            A JADE Professional subscription is required to access this service. For more information,
                            see the{" "}
                                <a
                                    href='https://openlaw.com.au/access/'
                                    className={`mt-2 text-green-600 hover:text-green-500 hover:underline font-plexSans subpixel-antialiased cursor-pointer`}
                                >
                                Open Law Services
                                </a>{" "}
                            page.
                            </p>
                            <p className={`mt-6 text-base text-gray-500`}>
                            You can subscribe to JADE Professional{" "}
                                <a
                                    href='https://jade.io/shop/cp/_1'
                                    className={`mt-2 text-green-600 hover:text-green-500 hover:underline font-plexSans subpixel-antialiased cursor-pointer`}
                                >
                                here
                                </a>
                            .
                            </p>
                            <p className={`mt-6 text-base text-gray-500`}>
                            Did you know, students can receive free access to JADE Professional while they remain
                            enrolled at a recognised Australian tertiary institution? Find more information and register
                            as a student{" "}
                                <a
                                    href='https://barnet.typeform.com/ebook-jade-pro'
                                    className={`mt-2 text-green-600 hover:text-green-500 hover:underline font-plexSans subpixel-antialiased cursor-pointer`}
                                >
                                here
                                </a>
                            .
                            </p>
                        </div>
                        <div className={`flex items-center p-6 space-x-2 rounded-b flex-row-reverse`}>
                            <button
                                onClick={() => setOpen(false)}
                                className='relative inline-flex items-center px-6 py-2 overflow-hidden text-lg font-medium text-jade-primary border-2 border-jade-primary hover:text-white group hover:bg-gray-50 rounded-md'
                            >
                                <span className='absolute left-0 block w-full h-0 transition-all bg-jade-primary opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease'></span>
                                <span className='absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease'></span>
                                <span className='relative text-jade-primary transition duration-300 group-hover:text-white ease'>
                                Close
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
        : null
}

export default ProfessionalModal
