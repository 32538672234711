import LoginButton from "./LoginButton"

type JurisdictionCoverage = {
    year: number | string;
    jurisdictions: string;
};

const CoverageDesc: JurisdictionCoverage[] = [
    {
        year: 1998,
        jurisdictions: "HCA, VSCA",
    },
    {
        year: 2002,
        jurisdictions: "FCAFC",
    },
    {
        year: 2008,
        jurisdictions: "QCA",
    },
    {
        year: 2011,
        jurisdictions: "NSWCA, NSWCCA",
    },
    {
        year: 2017,
        jurisdictions: "WASCA",
    },
    {
        year: "All",
        jurisdictions: "TASFC, SASCA, SASCFC, NTCA, ACTCA",
    },
]

interface Props {
    endpoint: string;
    authenticationFailed: boolean;
}

const Info = ({ endpoint, authenticationFailed }: Props) => {
    const textClass = "text-base font-plexSans text-left subpixel-antialiased"

    return (
        <div className={`mx-0 bg-white`}>
            <div className={`mx-0 md:mx-24 flex flex-col items-center`}>
                <div className={`my-2 px-4 py-5 overflow-hidden sm:p-6`}>
                    <p className={`${textClass} mb-4`}>
                        Jasmine{"™"} contains a subset of JADE{"®"}
                        {`'`}s extensive set of citations. Jasmine™ is under active development. Additional decisions
                        are continuously being added.
                    </p>
                    <p className={`${textClass} mb-4`}>
                        During the development stages, our coverage of online decisions is the following subset: from
                        1998 for the <b>High Court of Australia</b>, and the Court of Appeal of the{" "}
                        <b>Victorian Supreme Court</b> (which is the year in which the HCA and VSCA MNCs were first used
                        by those Courts), from 2002 for the Full Court of the Federal Court of Australia (which is the
                        year in which the MNC FCAFC was first used), from 2008 for the <b>Queensland Court of Appeal</b>
                        , from 2011 for the <b>New South Wales Court of Appeal and Court of Criminal Appeal</b>, from
                        2017 for the <b>Court of Appeal of the Supreme Court of Western Australia</b>, and all of the
                        decisions published with the following MNCs: <b>TASFC</b>, <b>SASCA</b>, <b>SASCFC</b>,{" "}
                        <b>NTCA</b>, and <b>ACTCA</b>, being Full Court of the Supreme Court of Tasmania, the South
                        Australian intermediate appellate courts (which changed from SASCFC to SASCA upon the creation
                        of a separate court of Appeal), the Northern Territory, and the Australian Capital Territory.
                    </p>
                    {/* {CoverageDesc.map((cd: JurisdictionCoverage, idx: number) => (
                         <li>
                         <span class={tw`${textClass} font-medium`}>
                         {cd.year}
                         </span>
                         {": "}
                         <span class={tw`${textClass}`}>{cd.jurisdictions}</span>
                         </li>
                         ))} */}
                    <p className={`${textClass} mt-4`}>You should always run a search in JADE for greater coverage.</p>
                    <p className={`${textClass} mt-4 text-center font-bold`}>
                        Jasmine™ is available to JADE Professional subscribers.
                    </p>
                </div>
                <LoginButton authenticationFailed={authenticationFailed} endpoint={endpoint} />
            </div>
        </div>
    )
}

export default Info
